import {
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { TemplateJSONEditor } from './template-json-editor';
import { TemplateLabelsForm } from './template-labels-form';
import { TemplateValuesForm } from './template-values-form';
import {
  AuthWithGoogleButton,
  isIapAuthRequired,
  resetIapAuthRequired
} from 'security/iap';
import { setMessages } from 'store/actions/actions';
import { useGlobalState } from 'store/reducers/reducer';
import {
  adminDeleteTemplate,
  adminLoadTemplate,
  adminPromoteTemplate,
  adminUpdateTemplateConfiguration,
  Configuration,
  ExtractionField,
  TemplateItem,
  Word
} from 'store/sagas/sagas';

const boxToPoints = (box: Box) => {
  return [
    { x: box.left, y: box.top },
    { x: box.left + box.width, y: box.top },
    {
      x: box.left + box.width,
      y: box.top + box.height
    },
    { x: box.left, y: box.top + box.height }
  ];
};

interface PointConfig {
  match_whole_line: boolean;
  word: string;
  points: Point[];
}

type TupleRect = [number, number, number, number];

export interface WordsPointConfig extends PointConfig {
  type: 'words';
  count: number;
  match: string;
  weight: number;
  image: boolean;
  word: string;
}

export interface TransformedFieldConfig {
  type: 'extraction';
  regex: string | null;
  match: string[] | string | null;
  match_whole_line: boolean | null;
  word: string;
  points: Point[];
}

const toConfigPointsExtractionFields = (
  extractionFields?: Record<string, ExtractionField>
) => {
  if (!extractionFields) {
    return [];
  }

  return Object.keys(extractionFields)
    .reduce<TransformedFieldConfig[]>((acc, key) => {
      const field = extractionFields[key]!;
      const [left, top, width, height] = field.rect;

      const points = boxToPoints({ left, top, width, height });
      return [
        ...acc,
        {
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          regex: field.regex || null,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          match: field.match || null,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          match_whole_line: field.match_whole_line || null,
          word: key,
          type: 'extraction' as const,
          points
        }
      ];
    }, [])
    .sort((a, b) => {
      const aWord = a.word.toUpperCase();
      const bWord = b.word.toUpperCase();
      if (aWord < bWord) {
        return -1;
      }

      if (aWord > bWord) {
        return 1;
      }

      return 0;
    });
};

interface Point {
  x: number;
  y: number;
}

const toConfigPointsWords = (words: Record<string, Word>) => {
  return Object.entries(words)
    .reduce<WordsPointConfig[]>((acc, [key, value]) => {
      const rects = value.rects.map((r, idx) => {
        const [left, top, width, height] = r;
        const points = boxToPoints({ left, top, width, height });
        const images = value.images ?? value.weights.map(() => false);
        return {
          weight: value.weights[idx],
          count: value.counts[idx],
          match_whole_line: value.match_whole_line,
          // match: configuration.words[key].match[idx],
          match:
            value.match && value.match.length > idx ? value.match[idx] : null,
          image: images[idx],
          word: key,
          type: 'words',
          points
        } as WordsPointConfig;
      });

      return [...acc, ...rects];
    }, [])
    .sort((a, b) => {
      const aWord = a.word.toUpperCase();
      const bWord = b.word.toUpperCase();
      if (aWord < bWord) {
        return -1;
      }

      if (aWord > bWord) {
        return 1;
      }

      return 0;
    });
};

const getRectanglePoints = (points: Point[]): TupleRect => {
  if (points.length < 4) {
    throw new Error('Must have at least 4 points');
  }

  const left = points[0]!.x;
  const top = points[0]!.y;
  const width = points[2]!.x - points[0]!.x;
  const height = points[3]!.y - points[0]!.y;

  return [left, top, width, height];
};

interface WordConfig {
  counts: number[];
  weights: number[];
  images?: boolean[];
  rects: TupleRect[];
  match_whole_line?: boolean;
  match?: string[];
}

interface Config {
  words: Record<string, WordConfig>;
  extraction_fields?: Record<string, ExtractionField>;
}

const isWordPointConfig = (
  obj: TransformedFieldConfig | WordsPointConfig
): obj is WordsPointConfig => {
  return obj.type === 'words';
};

const listPointsToConfig = (
  listPoints: (TransformedFieldConfig | WordsPointConfig)[]
) => {
  const configObject: Config = {
    words: {},
    extraction_fields: {}
  };

  for (const listPoint of listPoints) {
    if (isWordPointConfig(listPoint)) {
      const subList = configObject.words[listPoint.word];
      if (subList) {
        subList.counts = [...subList.counts, listPoint.count];
        subList.weights = [...subList.weights, listPoint.weight];
        subList.match_whole_line = !!listPoint.match;
        subList.images = [...(subList.images ?? []), listPoint.image];
        subList.rects = [
          ...subList.rects,
          getRectanglePoints(listPoint.points)
        ];
      } else {
        configObject.words[listPoint.word] = {
          rects: [getRectanglePoints(listPoint.points)],
          weights: [listPoint.weight],
          images: [listPoint.image],
          counts: [listPoint.count],
          match: listPoint.match ? [listPoint.match] : []
        };
      }
    } else {
      const entry: ExtractionField = {
        rect: getRectanglePoints(listPoint.points),
        regex: listPoint.regex
      };

      if (listPoint.match) {
        entry.match = listPoint.match as string;
      }

      configObject.extraction_fields![listPoint.word] = entry;
    }
  }

  return configObject;
};

interface Box {
  top: number;
  left: number;
  height: number;
  width: number;
}

export interface AdditionalToggleValues {
  has_PDF417_back?: boolean;
  pdf417Reconcile?: boolean;
  lastname_first?: boolean;
  comma_separated_name?: boolean;
  case_separated_name?: boolean;
  is_paper?: boolean;
}

export interface AdditionalDropdownValues {
  require_side: 'back' | null;
  side: 'back' | null;
  presetExpirationDay: string | null;
  presetExpirationMonth: string | null;
}

interface RouteParams {
  id: string;
}

const openDebugResults = (text: string) => {
  const myWindow = window.open('');
  if (!myWindow) {
    return;
  }

  myWindow.document.write(`<div>
        <pre>${text}</pre>
        <div>`);

  myWindow.document.close();
};

export const TemplateForm: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { asyncDispatch, dispatch } = useGlobalState();

  const [drawingMode, setDrawingMode] = useState(false);
  const [drawRectangleMode, setDrawRectangleMode] = useState(false);
  const [newWord, setNewWord] = useState('');
  const [newExtractionWord, setNewExtractionWord] = useState<string>('');

  const [extractionEditWord, setExtractionEditWord] = useState<number | null>(
    null
  );

  const [editWord, setEditWord] = useState<number | null>(null);
  const [listPoints, setListPoints] = useState<WordsPointConfig[]>([]);
  const [extractionListPoints, setExtractionListPoints] = useState<
    TransformedFieldConfig[]
  >([]);

  const [template, setTemplate] = useState<TemplateItem | null>(null);

  const [viewJson, setViewJson] = useState(false);
  const [config, setConfig] = useState<Configuration | null>(null);
  const [layout, setLayout] = useState<'column' | 'row'>('column');
  const [active, setActive] = useState(false);

  const { id } = useParams<RouteParams>();

  useEffect(() => {
    resetIapAuthRequired();
    asyncDispatch(adminLoadTemplate(id))
      .then((loadedTemplate) => {
        try {
          setConfig(loadedTemplate.configuration);

          const relevantConfig =
            loadedTemplate.configuration ?? loadedTemplate.live_configuration;

          const wordPoints = relevantConfig
            ? toConfigPointsWords(relevantConfig.words)
            : [];

          const extractionPoints = relevantConfig
            ? toConfigPointsExtractionFields(relevantConfig.extraction_fields)
            : [];

          setListPoints(wordPoints);
          setExtractionListPoints(extractionPoints);
          setTemplate(loadedTemplate);
          setActive(loadedTemplate.active);
        } catch (error) {
          console.error(error);
          dispatch(
            setMessages([
              {
                value: 'Unable to load configuration properties',
                severity: 'error'
              }
            ])
          );
        }
      })
      .catch(console.error);
  }, []);

  const addNewWord = () => {
    const newPoints = listPoints;

    const newIndex = listPoints.length;
    newPoints.push({
      count: 1,
      image: false,
      match: '',
      match_whole_line: false,
      points: [],
      type: 'words',
      weight: 1,
      word: newWord
    });

    setListPoints(newPoints);

    setEditWord(newIndex);
    setDrawRectangleMode(true);
  };

  const addNewExtractionWord = () => {
    const newPoints = extractionListPoints;

    const newIndex = extractionListPoints.length;
    newPoints.push({
      match: '',
      points: [],
      type: 'extraction',
      match_whole_line: null,
      regex: '',
      word: newExtractionWord
    });

    setExtractionListPoints(newPoints);

    setExtractionEditWord(newIndex);
    setDrawRectangleMode(true);
  };

  const overwriteRectangle = (index: number, points: Point[]) => {
    const newPoints = [...listPoints];
    newPoints[index]!.points = points;

    if (points.length === 4) {
      setEditWord(null);
      setDrawRectangleMode(false);
    }

    setListPoints(newPoints);
  };

  const overwriteExtractionRectangle = (index: number, points: Point[]) => {
    const newPoints = [...extractionListPoints];
    newPoints[index]!.points = points;

    if (points.length === 4) {
      setExtractionEditWord(null);
      setDrawRectangleMode(false);
    }

    setExtractionListPoints(newPoints);
  };

  const handleUpdateTemplate = () => {
    if (!template) {
      return;
    }

    const newPoints = listPointsToConfig([
      ...listPoints,
      ...extractionListPoints
    ]);

    const updatedConfig: Configuration = {
      ...config!,
      ...newPoints
    };

    setConfig(updatedConfig);

    asyncDispatch(
      adminUpdateTemplateConfiguration({
        sid: template.sid,
        configuration: updatedConfig,
        active
      })
    )
      .then(() => {
        dispatch(
          setMessages({
            value: `Template updated`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  const deleteTemplate = () => {
    if (!template) {
      return;
    }

    // eslint-disable-next-line no-alert
    if (!confirm('Are you sure you want to delete this template?')) {
      return;
    }

    asyncDispatch(adminDeleteTemplate(template.id))
      .then(() => {
        dispatch(
          setMessages({
            value: `Template deleted. Reload page or go back to the templates dashboard to confirm`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  const promoteTemplate = () => {
    if (!template) {
      return;
    }

    asyncDispatch(adminPromoteTemplate(template.sid))
      .then(() => {
        dispatch(
          setMessages({
            value: `Template promoted`,
            severity: 'success'
          })
        );
      })
      .catch(console.error);
  };

  const handleAdditionalValueChange = useCallback(
    (value: Partial<Configuration>) => {
      if (Object.keys(value)[0] === 'presetExpiration') {
        setConfig((prevState) => ({
          ...prevState!,
          presetExpiration: {
            ...prevState!.presetExpiration,
            ...value.presetExpiration
          }
        }));
      } else {
        setConfig((prevState) => ({ ...prevState!, ...value }));
      }
    },
    []
  );

  const toggleValues: AdditionalToggleValues = {
    has_PDF417_back: config?.has_PDF417_back ?? false,
    pdf417Reconcile: config?.pdf417Reconcile ?? false,
    lastname_first: config?.lastname_first ?? false,
    comma_separated_name: config?.comma_separated_name ?? false,
    case_separated_name: config?.case_separated_name ?? false,
    is_paper: config?.is_paper ?? false
  };

  const dropdownValues = {
    values: {
      require_side: config?.require_side ?? null,
      side: config?.side ?? null,
      presetExpirationDay: config?.presetExpiration?.day ?? null,
      presetExpirationMonth: config?.presetExpiration?.month ?? null
    } as AdditionalDropdownValues,
    options: {
      require_side: ['back'],
      side: ['back'],
      presetExpirationDay: Array.from({ length: 31 }, (_, i) =>
        (i + 1).toString().padStart(2, '0')
      ),
      presetExpirationMonth: Array.from({ length: 12 }, (_, i) =>
        (i + 1).toString().padStart(2, '0')
      )
    }
  };

  return (
    <div>
      {isIapAuthRequired() && (
        <Container>
          This page is protected by IAP - <AuthWithGoogleButton />
        </Container>
      )}
      <h1>Template Editor</h1>
      {template ? (
        <div>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={drawingMode}
                  onChange={() => {
                    setDrawingMode(!drawingMode);
                  }}
                />
              }
              label="Extraction mode"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={layout === 'row'}
                  onChange={() => {
                    setLayout((curr) => (curr === 'row' ? 'column' : 'row'));
                  }}
                />
              }
              label="Row layout"
            />
          </FormGroup>
          {drawingMode ? (
            <TemplateValuesForm
              drawRectangleMode={drawRectangleMode}
              dropdownValues={dropdownValues}
              editWord={extractionEditWord}
              handleAdditionalValueChange={handleAdditionalValueChange}
              layout={layout}
              listPoints={extractionListPoints}
              newWord={newExtractionWord}
              onAddNewWord={addNewExtractionWord}
              overwriteRectangle={overwriteExtractionRectangle}
              photoDimensions={template.photo_dimensions}
              photoUrl={template.photo_url}
              setDrawRectangleMode={setDrawRectangleMode}
              setEditWord={setExtractionEditWord}
              setListPoints={setExtractionListPoints}
              setNewWord={setNewExtractionWord}
              toggleValues={toggleValues}
            />
          ) : (
            <TemplateLabelsForm
              drawRectangleMode={drawRectangleMode}
              editWord={editWord}
              layout={layout}
              listPoints={listPoints}
              newWord={newWord}
              onAddNewWord={addNewWord}
              overwriteRectangle={overwriteRectangle}
              photoDimensions={template.photo_dimensions}
              photoUrl={template.photo_url}
              setDrawRectangleMode={setDrawRectangleMode}
              setEditWord={setEditWord}
              setListPoints={setListPoints}
              setNewWord={setNewWord}
            />
          )}
          {viewJson && config && <TemplateJSONEditor values={config} />}
        </div>
      ) : null}
      <Grid container spacing={1}>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(_, checked) => {
                  setActive(checked);
                }}
              />
            }
            label="Active"
          />
        </Grid>
        <Grid item>
          <Button
            data-cy="json-editor"
            onClick={() => {
              setViewJson((prevState) => !prevState);
            }}
          >
            {viewJson ? 'Close JSON' : 'View JSON'}
          </Button>
        </Grid>

        <Grid item>
          <Button onClick={promoteTemplate}>Promote</Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              if (!template) {
                return;
              }

              openDebugResults(JSON.stringify(template, null, 1));
            }}
          >
            Download
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleUpdateTemplate}>Update</Button>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            onClick={deleteTemplate}
            variant="contained"
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
