import { Box, Icons, Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { DOBCaption } from './DOBCaption';
import { DOBCompletedResult } from './DOBCompletedResult';
import { DOBRequestDataTable } from './DOBRequestDataTable';
import { useGlobalState } from 'store/reducers/reducer';
import { DobJob, getDobJob } from 'store/sagas/sagas';

interface DOBDropdownsProps {
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const DOBDropdowns: FC<DOBDropdownsProps> = ({ jobSid, jobStatus }) => {
  const { asyncDispatch } = useGlobalState();
  const [dobJob, setDobJob] = useState<DobJob | null>(null);

  useEffect(() => {
    asyncDispatch(getDobJob(jobSid)).then(setDobJob, console.error);
  }, [jobSid]);

  if (!dobJob)
    return (
      <Dropdown title="DOB">
        <Box>
          <DOBCaption />
          <Icons.Loading />
        </Box>
      </Dropdown>
    );

  const { requestData, responseData } = dobJob;

  if (!responseData) {
    return (
      <Dropdown title="DOB">
        <Box>
          <DOBCaption />
          <Text>
            DOB verification could not be completed. Please try again or contact
            support.
          </Text>
          {requestData && <DOBRequestDataTable requestData={requestData} />}
        </Box>
      </Dropdown>
    );
  }

  return (
    <DOBCompletedResult
      dobIsMatch={responseData.dobMatch}
      jobStatus={jobStatus}
      requestData={requestData}
    />
  );
};
