import { Box, Text, ThemeContext } from '@vouched.id/vault';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { JobStatusLabel } from './JobStatusLabel';
import { ReviewedCheckbox } from './ReviewedCheckbox';
import { JobPageParams, JobStatus } from './utils';
import { humanDate } from 'utils/ui.utils';

interface JobStatusBarProps {
  readonly isAMLOnly: boolean;
  readonly status: JobStatus;
  readonly submittedAt: string;
  readonly updatedAt: string | null;
  readonly hasWarnings: boolean;
  readonly jobReviewSuccess: boolean | null;
  readonly jobAccountReviewed: boolean;
}

const Label: React.FC<{ readonly text: string; readonly color: string }> = ({
  text,
  color
}) => (
  <Text
    style={{
      color,
      textTransform: 'uppercase',
      marginBottom: '10px'
    }}
    variation="footnote"
  >
    {text}
  </Text>
);

export const JobStatusBar: React.FC<JobStatusBarProps> = ({
  isAMLOnly,
  status,
  submittedAt,
  updatedAt,
  hasWarnings,
  jobReviewSuccess,
  jobAccountReviewed
}) => {
  const { gray800 } = useContext(ThemeContext).theme.tokens.colors;
  const { id: jobSid } = useParams<JobPageParams>();

  return (
    <Box
      display="flex"
      flexDirection={['column', 'row']}
      flexWrap="wrap"
      style={{
        gap: '15px',
        justifyContent: ['center', 'space-around'],
        alignItems: ['flex-start', 'center']
      }}
    >
      <Box>
        <Label color={gray800} text="Vouched Status" />
        {isAMLOnly ? (
          <Text>Completed</Text>
        ) : (
          <JobStatusLabel hasWarnings={hasWarnings} status={status} />
        )}
      </Box>
      {typeof jobReviewSuccess === 'boolean' && (
        <Box>
          <Label color={gray800} text="Review Status" />
          <JobStatusLabel
            hasWarnings={false}
            status={jobReviewSuccess ? 'approved' : 'rejected'}
          />
        </Box>
      )}
      <Box>
        <Label color={gray800} text="Submitted" />
        <Text>{humanDate(submittedAt)}</Text>
      </Box>
      <Box>
        <Label color={gray800} text="Updated" />
        <Text>{updatedAt ? humanDate(updatedAt) : '-'}</Text>
      </Box>
      <Box>
        <Label color={gray800} text="Job Id" />
        <Text>{jobSid}</Text>
      </Box>
      <Box>
        <Label color={gray800} text="Viewed" />
        <ReviewedCheckbox
          jobAccountReviewed={jobAccountReviewed}
          jobSid={jobSid}
        />
      </Box>
    </Box>
  );
};
