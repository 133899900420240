import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as ReactGA from 'react-ga';
import { Switch, useRouteMatch } from 'react-router-dom';
import { AccountProperties } from './account-manager/account-properties';
import { EditUserForm } from './users/edit-user-form';
import { Jobs } from 'components/account/jobs';
import { JobPage } from 'components/account/jobs-v2/JobPage';
import { Loader } from 'components/account/loader';
import { SidebarV2 } from 'components/account/sidebar-v2/sidebar-v2';
import { UserVerification } from 'components/account/user-verification';
import { AccountForm } from 'components/admin/account-manager/account-form';
import { AccountManager } from 'components/admin/account-manager/account-manager';
import { TemplateForm } from 'components/admin/templates/template-form';
import { TemplateManager } from 'components/admin/templates/template-manager';
import { AdminUsageReports } from 'components/admin/usage-reports/usage-reports';
import { UserManager } from 'components/admin/users/user-manager';
import { PrivateRoute } from 'components/private-route';
import {
  ADMIN_PERMISSIONS,
  isAuthorized,
  SUPPORT_ADMIN_PERMISSIONS
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';
import { getUserInfo } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { useFlags } from 'utils/hooks';

export const Admin: React.FC = () => {
  const { state, asyncDispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const { path } = useRouteMatch();

  useEffect(() => {
    if (process.env['REACT_APP_NODE_ENV'] === 'production') {
      ReactGA.initialize('UA-151415696-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  });

  useEffect(() => {
    if (!state.accessToken) history.push('/signin');
  });

  useEffect(() => {
    if (!state.user) {
      loadUserInfo();
    } else if (isLoading) {
      /*
       * already in from sign in / forget / sign up
       * clear loading
       */
      setIsLoading(false);
    }
  }, [state.user]);

  const loadUserInfo = () => {
    asyncDispatch(getUserInfo())
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isAdmin = state.user
    ? isAuthorized(state.user, ADMIN_PERMISSIONS)
    : false;

  const isSupportAdmin = state.user
    ? isAuthorized(state.user, SUPPORT_ADMIN_PERMISSIONS)
    : false;

  const [noScroll, setNoScroll] = useState(false);

  const { jobPageRedesign } = useFlags();

  return isLoading || jobPageRedesign === undefined ? (
    <Loader />
  ) : (
    <div className={classNames('account u-flex-row', { noscroll: noScroll })}>
      <SidebarV2 toggleScroll={setNoScroll} />

      <div className="u-padding-medium u-flex-1 u-flex-column u-align-vertical-center content">
        <UserVerification user={state.user} />
        <Switch>
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={Jobs}
              componentProps={{
                basePath: '/admin/jobs',
                isAdmin: true
              }}
              path={`${path}/jobs`}
              waitForAccount
            />
          )}
          {jobPageRedesign && (isAdmin || isSupportAdmin) && (
            <PrivateRoute component={JobPage} path={`${path}/job/:id`} />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={AccountProperties}
              path={`${path}/accounts/:id/properties`}
            />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={AccountForm}
              path={`${path}/accounts/:id`}
            />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={AccountManager}
              componentProps={{
                basePath: '/admin/accounts'
              }}
              path={`${path}/accounts`}
            />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute component={EditUserForm} path={`${path}/users/:id`} />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={UserManager}
              componentProps={{
                basePath: '/admin/users',
                isAdmin: true
              }}
              path={`${path}/users`}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              component={TemplateForm}
              path={`${path}/templates/:id`}
            />
          )}
          {isAdmin && (
            <PrivateRoute
              component={TemplateManager}
              componentProps={{
                basePath: '/admin/templates'
              }}
              path={`${path}/templates`}
            />
          )}
          {(isAdmin || isSupportAdmin) && (
            <PrivateRoute
              component={AdminUsageReports}
              path={`${path}/usage-reports`}
            />
          )}
        </Switch>
      </div>
    </div>
  );
};
