import './account.css';

import classNames from 'classnames';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as ReactGA from 'react-ga';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { BillingPayments } from './billing-payments';
import { Candidates } from './candidates';
import { CompanyGroups } from './company-groups';
import { CompanyUsers } from './company-users';
import { Dashboard } from './dashboard/dashboard';
import { GetStarted } from './get-started';
import { Jobs } from './jobs';
import { JobPage } from './jobs-v2/JobPage';
import { Loader } from './loader';
import { Profile } from './profile/profile';
import { SidebarV2 } from './sidebar-v2/sidebar-v2';
import { SourceKey } from './source-key';
import { UpdateAccountInfoPage } from 'components/create-account';
import { PrivateRoute } from 'components/private-route';
import { VerificationPage } from 'components/verification-page';
import {
  ACCOUNT_ADMIN_PERMISSIONS,
  isAuthorized,
  REVIEWER_PERMISSIONS
} from 'security/authorization';
import { useGlobalState } from 'store/reducers/reducer';
import { getUserInfo } from 'store/sagas/sagas';
import { history } from 'utils/history';
import { useFlags } from 'utils/hooks';

export const Account: React.FC = () => {
  const { state, asyncDispatch } = useGlobalState();
  const [isLoading, setIsLoading] = useState(true);
  const [userIdentified, setUserIdentified] = useState(false);
  const { path } = useRouteMatch();
  const ldClient = useLDClient();

  useEffect(() => {
    if (process.env['REACT_APP_NODE_ENV'] === 'production') {
      ReactGA.initialize('UA-151415696-1');
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, []);

  useEffect(() => {
    if (!state.accessToken) history.push('/signin');
  });

  useEffect(() => {
    if (!state.accountGroups) {
      loadUserInfo();
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, [state.accountGroups]);

  useEffect(() => {
    if (!state.user) {
      loadUserInfo();
    } else if (isLoading) {
      /*
       * already in from sign in / forget / sign up
       * clear loading
       */
      setIsLoading(false);
    }
  }, [state.user]);

  useEffect(() => {
    if (!state.user || !ldClient) return;

    const { id, accountId } = state.user;
    if (!id || !accountId) return;

    ldClient
      .identify({
        kind: 'multi',
        org: {
          key: String(accountId)
        },
        user: {
          key: String(id),
          accountId: String(accountId)
        }
      })
      .then(() => {
        setUserIdentified(true);
      })
      .catch(console.error);
  }, [state.user, ldClient]);

  const loadUserInfo = () => {
    void asyncDispatch(getUserInfo()).finally(() => {
      setIsLoading(false);
    });
  };

  const isAccountAdmin = state.user
    ? isAuthorized(state.user, ACCOUNT_ADMIN_PERMISSIONS)
    : false;

  const isReviewer = state.user
    ? isAuthorized(state.user, REVIEWER_PERMISSIONS)
    : false;

  const [noScroll, setNoScroll] = useState(false);

  const { dashboardFeature, jobPageRedesign } = useFlags();

  return isLoading ||
    dashboardFeature === undefined ||
    jobPageRedesign === undefined ||
    !userIdentified ? (
    <Loader />
  ) : (
    <div className={classNames('account u-flex-row', { noscroll: noScroll })}>
      <SidebarV2 toggleScroll={setNoScroll} />

      <div className="u-padding-medium u-flex-1 u-flex-column u-align-vertical-center content">
        <Switch>
          {isAccountAdmin && (
            <PrivateRoute
              component={UpdateAccountInfoPage}
              path={`${path}/update-account-info`}
            />
          )}

          {isAccountAdmin && (
            <PrivateRoute component={CompanyUsers} path={`${path}/users`} />
          )}
          {isAccountAdmin && (
            <PrivateRoute component={CompanyGroups} path={`${path}/groups`} />
          )}
          {isAccountAdmin && (
            <PrivateRoute component={SourceKey} path={`${path}/key`} />
          )}
          {isAccountAdmin && (
            <PrivateRoute component={GetStarted} path={`${path}/get-started`} />
          )}
          {isAccountAdmin && (
            <Route
              component={BillingPayments}
              path={`${path}/billing-payments`}
            />
          )}
          {isReviewer && dashboardFeature && (
            <PrivateRoute
              component={Dashboard}
              componentProps={{
                basePath: '/account/dashboard',
                isAdmin: false
              }}
              path={`${path}/dashboard`}
            />
          )}

          {jobPageRedesign && isReviewer && (
            <PrivateRoute component={JobPage} path={`${path}/job/:id`} />
          )}

          {isReviewer && (
            <PrivateRoute
              component={Jobs}
              componentProps={{
                basePath: '/account/jobs',
                isAdmin: false
              }}
              path={`${path}/jobs`}
              waitForAccount
            />
          )}

          {isReviewer && (
            <PrivateRoute component={Candidates} path={`${path}/candidates`} />
          )}

          <PrivateRoute
            component={VerificationPage}
            path={`${path}/needs-verification`}
          />

          <PrivateRoute component={Profile} path={path} />
        </Switch>
      </div>
    </div>
  );
};
