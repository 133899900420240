import { Box, Text } from '@vouched.id/vault';
import React from 'react';
import { ImageSlider } from '../jobs/image-slider';
import { Image } from '../jobs/image-slider/smart-image';
import { barcodeImagePlaceholder } from '../jobs/job-modal/job-modal';
import { dropdownWrapperStyle } from './dropdowns/utils';
import { DigitalID, DigitalIDData } from './mdl/DigitalID';
import { JobImageType } from 'models/jobs/jobs';

interface JobImagesProps {
  readonly frontIdImages: Image[];
  readonly backIdImages: Image[];
  readonly userImages: Image[];
  readonly isReverification: boolean;
  readonly includeBarcode: boolean;
  readonly digitalIDData?: DigitalIDData;
}

export const JobImages: React.FC<JobImagesProps> = ({
  frontIdImages,
  backIdImages,
  userImages,
  isReverification,
  includeBarcode,
  digitalIDData
}) => {
  const hasFrontIdImages = frontIdImages.length > 0;
  const hasBackIdImages = backIdImages.length > 0 || includeBarcode;
  const hasUserImages = userImages.length > 0;

  if (
    !digitalIDData &&
    !hasFrontIdImages &&
    !hasBackIdImages &&
    !hasUserImages
  ) {
    return <Text>No images have been submitted yet for this job.</Text>;
  }

  return (
    <Box
      display="grid"
      marginBottom="spacing.large"
      style={{
        ...dropdownWrapperStyle,
        columnGap: '5px',
        gridTemplateColumns: digitalIDData
          ? 'min-content 33%'
          : 'repeat(3, calc(100% / 3 - 5px))'
      }}
    >
      {digitalIDData && <DigitalID data={digitalIDData} />}
      {!digitalIDData && hasFrontIdImages && (
        <ImageSlider
          imageType={JobImageType.IDFront}
          images={frontIdImages}
          selectedImage={0}
          type={isReverification ? 'Original' : ''}
        />
      )}
      {!digitalIDData &&
        hasBackIdImages &&
        (backIdImages.length ? (
          <ImageSlider
            imageType={JobImageType.IDBack}
            images={backIdImages}
            selectedImage={0}
            type={isReverification ? 'Reverification' : ''}
          />
        ) : (
          barcodeImagePlaceholder
        ))}
      {hasUserImages && (
        <ImageSlider
          imageType={JobImageType.Selfie}
          images={userImages}
          selectedImage={0}
        />
      )}
    </Box>
  );
};
