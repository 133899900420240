import { Box } from '@vouched.id/vault';
import React, { FC } from 'react';
import { LicenseField, PropertyGroup } from './LicenseField';
import { JobRequest, JobResult } from 'models/jobs/jobs';

export interface DigitalIDData {
  idType?: string;
  issuingCountry: string;
  issuingAuthority: string;
  familyName: string;
  givenName: string;
  birthDate: string;
  issueDate: string;
  expireDate: string;
  documentNumber: string;
  gender: string;
  height: string;
  eyeColor: string;
  address: string;
  digitalPortraitPhotoUrl?: string;
}

export function extractDigitalIDData(
  request: JobRequest,
  result?: JobResult
): DigitalIDData {
  const { parameters } = request;

  return {
    idType: result?.idType,
    issuingCountry: result?.country ?? 'N/A',
    issuingAuthority: result?.country ?? 'N/A',
    familyName: result?.lastName ?? 'N/A',
    givenName: result?.firstName ?? 'N/A',
    birthDate: result?.dob ?? 'N/A',
    issueDate: result?.issueDate ?? 'N/A',
    expireDate: result?.expireDate ?? 'N/A',
    documentNumber: result?.documentNumber ?? result?.id ?? 'N/A',
    gender: result?.gender?.gender ?? 'N/A',
    height: result?.height ?? 'N/A',
    eyeColor: result?.eyeColor ?? 'BRN',
    address: result?.address ?? '123 Street Ave, City, State 12345',
    digitalPortraitPhotoUrl: parameters!.digitalPortraitPhotoUrl
  };
}

interface MockDigitalIDProps {
  readonly data: DigitalIDData;
}

const formatTitleCase = (input: string | null | undefined): string => {
  if (!input) return 'Unknown';

  return input
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const DigitalID: FC<MockDigitalIDProps> = ({ data }) => {
  return (
    <Box
      className="mock-digital-id"
      style={{
        minHeight: '300px',
        backgroundColor: 'white',
        borderRadius: '16px',
        padding: '1.75rem',
        boxShadow:
          'rgba(0, 0, 0, 0.12) 0px 0.5px 4px, rgba(0, 0, 0, 0.12) 0px 6px 13px'
      }}
      width={['100%', '100%', '500px']}
    >
      {/* Card Header */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'baseline'
        }}
      >
        {/* Title - Doc Type */}
        <Box
          style={{
            fontSize: '2.5rem',
            fontWeight: '600',
            display: 'flex',
            gap: '0.5rem',
            alignItems: 'flex-start'
          }}
        >
          Mobile {formatTitleCase(data.idType)}
        </Box>
      </Box>

      {/* Card Content */}
      <Box
        style={{
          display: 'flex',
          height: '-webkit-fill-available',
          justifyContent: 'space-between',
          marginTop: '0.5rem',
          paddingTop: '0.5rem',
          gap: '1.5rem'
        }}
      >
        {/* Left Main */}
        <Box>
          <Box style={{ position: 'relative' }}>
            <img
              src={data.digitalPortraitPhotoUrl}
              style={{
                width: '148px',
                height: '205px',
                backgroundColor: '#555e74',
                borderRadius: '8px',
                boxShadow: '0 0 0 4px #f3f5f6 inset',
                border: '2px solid rgb(227, 230, 238)',
                position: 'relative',
                flexShrink: 0
              }}
            />
            <Box
              style={{
                position: 'absolute',
                inset: 0,
                borderRadius: '10px',
                boxShadow: 'inset 0 0 0 4px #f3f5f6',
                pointerEvents: 'none'
              }}
            />
          </Box>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '1rem auto auto',
              fontSize: '1.1rem'
            }}
          >
            Digital Identification
          </Box>
        </Box>

        {/* Right Main */}
        <Box
          style={{
            borderTop: '1px solid rgb(0, 0, 0, 0.1)',
            paddingTop: '1.5rem',
            width: '100%'
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%'
            }}
          >
            {/* Property Column */}
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem'
              }}
            >
              {/* chunk */}
              <PropertyGroup>
                <LicenseField label="DL" value={data.documentNumber} />
                <LicenseField label="exp" value={data.expireDate} />
              </PropertyGroup>

              {/* chunk */}
              <PropertyGroup>
                <LicenseField label="LN" value={data.familyName} />
                <LicenseField label="FN" value={data.givenName} />
              </PropertyGroup>
              <p
                style={{
                  lineHeight: '1.3',
                  fontWeight: '600',
                  margin: '0',
                  fontSize: '1.5rem'
                }}
              >
                {data.address.split(',')[0]}
                <br /> {data.address.split(',').slice(1).join(',').trim()}
              </p>
            </Box>

            {/* Tertiary Properties */}
            <Box
              style={{
                display: 'grid',
                gridTemplateRows: '1fr 1fr',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: '0.5rem',
                flexWrap: 'wrap'
              }}
            >
              <LicenseField label="SEX" value={data.gender} variant="compact" />
              <LicenseField label="HAIR" value="BRN" variant="compact" />
              <LicenseField
                label="EYES"
                value={data.eyeColor}
                variant="compact"
              />
              <LicenseField
                label="DOB"
                value={data.birthDate}
                variant="compact"
              />
              <LicenseField label="HGT" value={data.height} variant="compact" />
              <LicenseField label="WGT" value="300 lb" variant="compact" />

              <LicenseField
                label="ISS"
                value={data.issueDate}
                variant="compact"
              />

              <LicenseField
                label="IACA"
                value={data.issuingAuthority}
                variant="compact"
              />
              <LicenseField
                label="IACA"
                value={data.issuingAuthority}
                variant="compact"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
