import { Box, Icons, Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Dropdown } from '../Dropdown';
import { AMLCaption } from './AMLCaption';
import { AMLCompletedResult } from './AMLCompletedResult';
import { JobStatus } from 'components/account/jobs-v2/utils';
import { JobError } from 'models/jobs/jobs';
import { useGlobalState } from 'store/reducers/reducer';
import { AMLJobResult, retrieveAmlDetailed } from 'store/sagas/sagas';

interface AMLDropdownsProps {
  readonly isAMLOnly: boolean;
  readonly jobAMLResults?: { data?: object };
  readonly jobErrors: JobError[];
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const AMLDropdowns: FC<AMLDropdownsProps> = ({
  isAMLOnly,
  jobAMLResults,
  jobErrors,
  jobSid,
  jobStatus
}) => {
  const { asyncDispatch, state } = useGlobalState();
  const [amlDetails, setAmlDetails] = useState<AMLJobResult | undefined>();
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const isSandboxMode = state.sandboxToggled && !isAdminRoute;

  useEffect(() => {
    if (jobAMLResults) {
      asyncDispatch(retrieveAmlDetailed(jobSid, isSandboxMode)).then(
        setAmlDetails,
        console.error
      );
    }
  }, [asyncDispatch, jobSid, isSandboxMode, jobAMLResults]);

  if (!amlDetails)
    return (
      <Dropdown title="AML">
        <Box>
          <AMLCaption />
          <Icons.Loading />
        </Box>
      </Dropdown>
    );

  const { data } = amlDetails;

  if (!data) {
    return (
      <Dropdown title="AML">
        <Box>
          <AMLCaption />
          <Text>
            AML could not be completed. Please try again or contact support.
          </Text>
        </Box>
      </Dropdown>
    );
  }

  return (
    <AMLCompletedResult
      amlDetailsData={data}
      isAMLOnly={isAMLOnly}
      jobErrors={jobErrors}
      jobStatus={jobStatus}
    />
  );
};
