import { Box, Icons, Text } from '@vouched.id/vault';
import React, { FC, useEffect, useState } from 'react';
import { Dropdown } from '../../Dropdown';
import { SSNCaption } from '../SSNCaption';
import { PrivateSSNCompletedResult } from './PrivateSSNCompletedResult';
import { PrivateSSNRequestDataTable } from './PrivateSSNRequestDataTable';
import { JobType } from 'components/account/jobs/job_type';
import { JobStatus } from 'components/account/jobs-v2/utils';
import { useGlobalState } from 'store/reducers/reducer';
import { getPrivateSSNJob, PrivateSSNJob } from 'store/sagas/sagas';

interface PrivateSSNDropdownsProps {
  readonly jobSid: string;
  readonly jobStatus: JobStatus;
}

export const PrivateSSNDropdowns: FC<PrivateSSNDropdownsProps> = ({
  jobSid,
  jobStatus
}) => {
  const { asyncDispatch } = useGlobalState();
  const [privateSSNJob, setPrivateSSNJob] = useState<PrivateSSNJob | null>(
    null
  );

  useEffect(() => {
    asyncDispatch(getPrivateSSNJob(jobSid)).then(
      setPrivateSSNJob,
      console.error
    );
  }, [jobSid]);

  if (!privateSSNJob)
    return (
      <Dropdown title="SSN">
        <Box>
          <SSNCaption type={JobType.privateSSN} />
          <Icons.Loading />
        </Box>
      </Dropdown>
    );

  const { request_data, response_data } = privateSSNJob;

  const ssnIsMatch =
    response_data?.identifiers?.ssn ??
    response_data?.identifiers?.last4 ??
    response_data?.verified;

  if (ssnIsMatch === undefined) {
    return (
      <Dropdown title="SSN">
        <Box>
          <SSNCaption type={JobType.privateSSN} />
          <Text>
            SSN verification could not be completed. Please try again or contact
            support.
          </Text>
          <Box
            display="flex"
            marginTop="spacing.default"
            style={{ gap: '15px' }}
          >
            <Text>Source:</Text>
            <Text style={{ fontWeight: '600' }}>Private</Text>
          </Box>
          {request_data && (
            <PrivateSSNRequestDataTable requestData={request_data} />
          )}
        </Box>
      </Dropdown>
    );
  }

  return (
    <PrivateSSNCompletedResult
      jobStatus={jobStatus}
      requestData={request_data}
      ssnIsMatch={ssnIsMatch}
    />
  );
};
