import { Box } from '@vouched.id/vault';
import React, { FC, useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import { JobPageParams, JobStatus } from '../../utils';
import { Dropdown } from '../Dropdown';
import { useGlobalState } from 'store/reducers/reducer';
import { downloadJob } from 'store/sagas/sagas';
import { base64ToArrayBuffer, saveByteArray } from 'utils/ui.utils';

interface FilesDropdownProps {
  readonly jobStatus: JobStatus;
}

export const FilesDropdown: FC<FilesDropdownProps> = ({ jobStatus }) => {
  const { asyncDispatch, state } = useGlobalState();
  const { id: jobSid } = useParams<JobPageParams>();
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const isSandboxMode = state.sandboxToggled && !isAdminRoute;

  const handleDownload = useCallback(
    (sid: string, confValue: boolean) => {
      asyncDispatch(downloadJob(sid, confValue, isSandboxMode))
        .then((res) => {
          const fileName = `IDV_${sid}_Internal_Use.pdf`;
          const sampleBytes = base64ToArrayBuffer(res.pdf);
          saveByteArray([sampleBytes], fileName);
        })
        .catch(console.error);
    },
    [asyncDispatch, isSandboxMode]
  );

  return (
    <Dropdown title="Files">
      {jobStatus !== 'active' && (
        <>
          <Box marginBottom="spacing.default">
            <a
              onClick={() => {
                handleDownload(jobSid, true);
              }}
            >
              <i aria-hidden="true" className="pointer fas fa-download" />
              Report
            </a>
          </Box>
          <Box marginBottom="spacing.default">
            <a
              onClick={() => {
                handleDownload(jobSid, false);
              }}
            >
              <i aria-hidden="true" className="pointer fas fa-download" />
              NoConfReport
            </a>
          </Box>
        </>
      )}
    </Dropdown>
  );
};
