import React, { FC } from 'react';
import { MultiSpanTable, MultiSpanTableEntry } from '../MultiSpanTable';
import {
  CaptureType,
  captureTypeMap,
  JobRequestProperties
} from 'models/jobs/jobs';
import { humanIdType, humanPropertyName } from 'utils/jobs';

interface IDVDetailsTableProps {
  readonly extractedData: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    birthDate?: string;
    type?: string;
    id?: string;
    country?: string;
    state?: string;
    expireDate?: string;
    issueDate?: string;
    address?: string;
    class?: string;
    endorsements?: string;
    motorcycle?: string;
    captureType?: CaptureType;
  };
  readonly submittedData: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    email?: string;
    phone?: string;
  };
  readonly deviceData: {
    referer?: string;
    userAgent?: string;
    theme?: string;
    client?: string;
    ipAddress?: string;
  };
  readonly properties: JobRequestProperties;
}

export const IDVDetailsTable: FC<IDVDetailsTableProps> = ({
  extractedData,
  submittedData,
  deviceData,
  properties
}) => {
  const entries: MultiSpanTableEntry[] = [
    {
      title: 'Extracted',
      items: [
        {
          label: 'First Name',
          value: extractedData.firstName
        },
        {
          label: 'Middle Name',
          value: extractedData.middleName
        },
        {
          label: 'Last Name',
          value: extractedData.lastName
        },
        {
          label: 'Birth Date',
          value: extractedData.birthDate
        },
        {
          label: 'Type',
          value: humanIdType(extractedData.type) ?? undefined
        },
        {
          label: 'Capture Type',
          value: extractedData.captureType
            ? captureTypeMap[extractedData.captureType]
            : undefined
        },
        {
          label: 'ID Number',
          value: extractedData.id
        },
        {
          label: 'Address',
          value: extractedData.address
        },
        {
          label: 'Country',
          value: extractedData.country
        },
        {
          label: 'State',
          value: extractedData.state
        },
        {
          label: 'Expiration Date',
          value: extractedData.expireDate
        },
        {
          label: 'Issue Date',
          value: extractedData.issueDate
        },
        {
          label: 'Class',
          value: extractedData.class
        },
        {
          label: 'Endorsements',
          value: extractedData.endorsements
        },
        {
          label: 'Motorcycle',
          value: extractedData.motorcycle
        }
      ]
    },
    {
      title: 'Submitted',
      items: [
        {
          label: 'First Name',
          value: submittedData.firstName
        },
        {
          label: 'Last Name',
          value: submittedData.lastName
        },
        {
          label: 'Birth Date',
          value: submittedData.dob
        },
        {
          label: 'Email',
          value: submittedData.email
        },
        {
          label: 'Phone',
          value: submittedData.phone
        }
      ]
    },
    {
      title: 'Device',
      items: [
        {
          label: 'Referer',
          value: deviceData.referer
        },
        {
          label: 'User Agent',
          value: deviceData.userAgent
        },
        {
          label: 'Theme',
          value: deviceData.theme
        },
        {
          label: 'Client',
          value: deviceData.client
        },
        {
          label: 'IP Address',
          value: deviceData.ipAddress
        }
      ]
    },
    ...(properties && properties.length > 0
      ? [
          {
            title: 'Properties',
            items: properties.map((property) => {
              return {
                label:
                  property.name in humanPropertyName
                    ? humanPropertyName[
                        property.name as keyof typeof humanPropertyName
                      ]
                    : property.name,
                value: property.value
              };
            })
          }
        ]
      : [])
  ];

  return <MultiSpanTable entries={entries} header="Details" />;
};
