import { Checkbox } from '@vouched.id/vault';
import React, { FC, useCallback, useState } from 'react';
import { useLocation } from 'react-router';
import { useGlobalState } from 'store/reducers/reducer';
import { updateReviewStatus } from 'store/sagas/sagas';

interface ReviewedCheckboxProps {
  readonly jobSid: string;
  readonly jobAccountReviewed: boolean;
}

export const ReviewedCheckbox: FC<ReviewedCheckboxProps> = ({
  jobSid,
  jobAccountReviewed
}) => {
  const { asyncDispatch, state } = useGlobalState();
  const [checked, setChecked] = useState(jobAccountReviewed);
  const isAdminRoute = useLocation().pathname.includes('/admin');
  const isSandboxMode = state.sandboxToggled && !isAdminRoute;

  const handleCheck = useCallback(() => {
    const newChecked = !checked;
    setChecked(newChecked);
    asyncDispatch(
      updateReviewStatus({ id: jobSid, value: newChecked }, isSandboxMode)
    ).catch(console.error);
  }, [asyncDispatch, checked, jobSid, isSandboxMode]);

  return <Checkbox checked={checked} onClick={handleCheck} variation="small" />;
};
