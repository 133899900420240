import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { getSSNJob } from '../../../../store/sagas/sagas';
import { JobType } from '../job_type';
import { ModalTabs } from './job-modal-tabs/job-modal-tabs';
import { ImageSlider } from 'components/account/jobs/image-slider';
import { Job, JobImageType, JobUpdateReview } from 'models/jobs/jobs';
import { User, UserUpdateReview } from 'models/user/user.model';
import { useGlobalState } from 'store/reducers/reducer';
import { createImages } from 'utils/jobs';
import './job-modal.css';

interface Props {
  readonly job: Job;
  readonly user: User;
  readonly readonly: boolean | null;
  readonly debug: boolean | null;
  readonly onClose: () => void;
  readonly handleReview: (body: UserUpdateReview) => void;
  readonly handleJobReview: (id: string, body: JobUpdateReview | null) => void;
  readonly handleRemove: (id: string) => void;
}

const Transition = React.forwardRef<unknown, SlideProps>((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />;
});

Transition.displayName = 'Transition';

const CloseButton: FC<{ readonly onClick: () => void }> = ({ onClick }) => (
  <svg
    className="job-modal__close"
    data-test="modal-close-button"
    height="35px"
    onClick={onClick}
    viewBox="0 0 512 512"
    width="35px"
  >
    <circle cx="256" cy="256" fill="#DDD" r="256" />
    <line
      stroke="black"
      strokeWidth="25px"
      x1="150"
      x2="362"
      y1="150"
      y2="362"
    />
    <line
      stroke="black"
      strokeWidth="25px"
      x1="150"
      x2="362"
      y1="362"
      y2="150"
    />
  </svg>
);

export const barcodeImagePlaceholder = (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      border: '1px outset rgba(0, 0, 0, 0.2)',
      borderRadius: '5px'
    }}
  >
    Barcode was not able to be captured
  </div>
);

export const JobModal: React.FC<Props> = ({
  job,
  onClose,
  user,
  debug,
  readonly,
  handleJobReview,
  handleReview,
  handleRemove
}) => {
  const { parameters } = job.request;
  const images1 = parameters
    ? createImages(parameters, ['userDetect', 'user', 'userDistance', 'source'])
    : [];

  const images2 = parameters
    ? createImages(parameters, ['idDetect', 'id', 'idDistance', 'dest'])
    : [];

  const images3 = parameters ? createImages(parameters, ['backId']) : [];
  const hasBackIdImageSlot =
    images3.length > 0 || job.result?.clientOutput?.includeBarcode;

  let jobType = null;
  let sourceLabel = null;
  let destLabel = null;
  if (job.status === 'completed') {
    jobType = job.request.type === JobType.idReverification;
    sourceLabel = jobType ? 'Original' : '';
    destLabel = jobType ? 'Reverification' : '';
  }

  const { asyncDispatch } = useGlobalState();
  const [showImages, setShowImages] = useState(
    job.request.type !== JobType.idCrosscheck
  );

  const handleGetSSNJob = (id: string) => {
    asyncDispatch(getSSNJob(id))
      .then((res) => {
        if (res.length !== 0 && res[0]) setShowImages(false);
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (job.id) handleGetSSNJob(job.id);
  }, []);

  const jobDebugResults = useMemo(() => {
    if (!job.debugResults || !debug) {
      return undefined;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const jobResult = JSON.parse(job.debugResults);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (jobResult?.results) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return jobResult.results[0].result?.id?.docengine?.google?.detect as {
        id: string;
        country: string;
        state: string;
        type: string;
        version: string;
      };
    }

    return undefined;
  }, [job.debugResults]);

  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={onClose}
      open
      scroll="body"
    >
      <div className="job_modal_wrap">
        <div className="job-modal__container">
          <CloseButton onClick={onClose} />
          <div className="job-modal__main">
            <div className="job-modal__main__info-block">
              {showImages ? (
                <div
                  className={`job_modal__main_override_height job-modal__main__info-block__photo ${
                    debug ? '' : 'job-modal__main__info-block__photo-client'
                  }`}
                >
                  <div className="images">
                    <Grid container item spacing={2} xs={12}>
                      <Grid item xs={hasBackIdImageSlot ? 4 : 6}>
                        <ImageSlider
                          imageType={JobImageType.Selfie}
                          images={images1}
                          jobResult={jobDebugResults}
                          selectedImage={0}
                          type={sourceLabel}
                        />
                      </Grid>
                      <Grid item xs={hasBackIdImageSlot ? 4 : 6}>
                        <ImageSlider
                          imageType={JobImageType.IDFront}
                          images={images2}
                          jobResult={jobDebugResults}
                          selectedImage={0}
                          type={destLabel}
                        />
                      </Grid>
                      {hasBackIdImageSlot && (
                        <Grid item xs={4}>
                          {images3.length ? (
                            <ImageSlider
                              imageType={JobImageType.IDBack}
                              images={images3}
                              jobResult={jobDebugResults}
                              selectedImage={0}
                            />
                          ) : (
                            barcodeImagePlaceholder
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </div>
              ) : null}
              <ModalTabs
                debug={debug}
                handleJobReview={handleJobReview}
                handleRemove={handleRemove}
                handleReview={handleReview}
                job={job}
                onClose={onClose}
                readonly={readonly}
                user={user}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
