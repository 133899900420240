import qs from 'qs';
import { history } from 'utils/history';

export function getQueryVariable(key: string): string {
  // if (!key) return '';
  const query = window.location.search.slice(1);
  return key ? (qs.parse(query)[key] as string) : '';
}

export type QueryData = Record<string, boolean | number | string | null>;
type ParsedQueryData = Record<string, Date | boolean | number | string | null>;

const cleanQuery = (
  query: ParsedQueryData | null,
  initialData: ParsedQueryData
) =>
  Object.keys(query ?? {}).reduce<ParsedQueryData>((acc, key) => {
    if (!query) return acc;
    if (query[key] === initialData[key]) {
      return acc;
    }

    acc[key] = query[key]!;
    return acc;
  }, {});

export function isURL(value: string): boolean {
  const reg = /[\w#%()+./:=?@~]{2,256}\.[a-z]{2,6}\b([\w#%&+./:=?@~-]*)/g;

  const regLocahostAndIPURL = /^http(s)?:\/\/\w+(\.\w+)*(:\d+)?\/?(\/[\w.]*)*$/;

  const isValid =
    (reg.exec(value)?.length ?? 0) > 0 ||
    (regLocahostAndIPURL.exec(value)?.length ?? 0) > 0;

  return isValid;
}

export type MapData = Record<string, 'boolean' | 'date' | 'number' | 'string'>;

interface PushSearchSearchData {
  filter: QueryData;
  initialData: QueryData;
  mapData: MapData;
  path: string;
  reloadPage?: boolean;
}

export const pushFiltersSearchPath = ({
  filter,
  initialData,
  mapData,
  path,
  reloadPage = true
}: PushSearchSearchData): { isInitial: boolean } => {
  let acc: ParsedQueryData = {};
  for (const key of Object.keys(filter)) {
    const k = key;
    if (filter[k]) acc[key] = filter[k]!;
  }

  const existQuery = cleanQuery(
    transformQueryTypes(mapData, rawParseQuery()),
    initialData
  );

  acc = cleanQuery(acc, initialData);
  if (!isQueriesSame(existQuery, acc)) {
    const params = qs.stringify(acc);

    const targetPath = `${path}?${params}`;

    if (reloadPage) history.push(targetPath);
    else window.history.pushState('', '', targetPath);
  }

  return {
    isInitial: isQueriesSame(acc, cleanQuery(initialData, initialData))
  };
};

export const isQueriesSame = (
  q1: ParsedQueryData,
  q2: ParsedQueryData
): boolean => {
  // same size?
  const q1Len = Object.keys(q1).length;
  const q2Len = Object.keys(q2).length;
  if (q1Len !== q2Len) {
    return false;
  }

  return !Object.keys(q1).some((key) => q1[key] !== q2[key]);
};

export const rawParseQuery = (
  query: string | null | undefined = window.location.search.slice(1)
): Record<string, string> => {
  if (!query) {
    return {};
  }

  return qs.parse(query) as Record<string, string>;
};

export const transformQueryTypes = (
  struct: MapData,
  obj: QueryData
): ParsedQueryData => {
  const out: ParsedQueryData = {};
  for (const k of Object.keys(struct)) {
    let value: Date | boolean | number | string | undefined;
    switch (struct[k]) {
      case 'boolean': {
        value = Boolean(obj[k]);

        break;
      }

      case 'number': {
        value = Number(obj[k]);

        break;
      }

      case 'date': {
        value = new Date(obj[k] as string);

        break;
      }

      case 'string': {
        value = obj[k] as string;
        break;
      }

      default:
        break;
    }

    if (value !== undefined) out[k] = value;
  }

  return obj;
};

export const toQueryStringWithArraySupport = (
  filters: Record<string, boolean | number | string | null | undefined>
): string => {
  let params = '';
  for (const [key, value] of Object.entries(filters)) {
    if (value !== '' && value !== null && value !== undefined) {
      params += key === 'ids' ? `${key}=["${value}"]&` : `${key}=${value}&`;
    }
  }

  return params;
};
