import React, { FC } from 'react';

type LicenseFieldVariants = 'default' | 'compact';
interface LicenseFieldProps {
  readonly label: string;
  readonly value: string;
  readonly variant?: LicenseFieldVariants;
}
export const LicenseField: FC<LicenseFieldProps> = ({
  label,
  value,
  variant
}) => (
  <div
    style={{
      display: 'flex',
      gap: variant === 'compact' ? '0.5rem' : '1rem',
      alignItems: 'baseline'
    }}
  >
    <div
      style={{ fontSize: '1rem', textTransform: 'uppercase', color: '#888' }}
    >
      {label}
    </div>
    <div
      style={{
        fontWeight: '600',
        fontSize: variant === 'compact' ? '1rem' : '1.5rem'
      }}
    >
      {value}
    </div>
  </div>
);
export const PropertyGroup = ({
  children
}: {
  readonly children: React.ReactNode;
}): JSX.Element => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem'
    }}
  >
    {children}
  </div>
);
