import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  withStyles
} from '@material-ui/core';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import cleanRegExp from 'clean-regexp';
import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import {
  AdditionalDropdownValues,
  AdditionalToggleValues,
  TransformedFieldConfig
} from './template-form';
import { useTableScroll } from './utils';
import {
  Dim,
  SmartImage
} from 'components/account/jobs/image-slider/smart-image';
import { StyledSwitch } from 'components/account/jobs/job-modal/job-modal-tabs/job-modal-tabs';
import { Configuration } from 'store/sagas/sagas';

interface Point {
  x: number;
  y: number;
}

interface Props {
  readonly onAddNewWord: () => void;
  readonly drawRectangleMode?: boolean;
  readonly editWord: number | null;
  readonly listPoints: TransformedFieldConfig[];
  readonly newWord: string;
  readonly overwriteRectangle?: (index: number, a: Point[]) => void;
  readonly photoDimensions?: Dim;
  readonly photoUrl?: string;
  readonly setDrawRectangleMode: (mode: boolean) => void;
  readonly setEditWord: (val: number | null) => void;
  readonly setListPoints: (
    val:
      | TransformedFieldConfig[]
      | ((val: TransformedFieldConfig[]) => TransformedFieldConfig[])
  ) => void;
  readonly setNewWord: (word: string) => void;
  readonly handleAdditionalValueChange: (value: Partial<Configuration>) => void;
  readonly toggleValues: AdditionalToggleValues;
  readonly dropdownValues: {
    values: AdditionalDropdownValues;
    options: Record<string, string[]>;
  };
  readonly layout: 'column' | 'row';
}

const LargeTableCell = styled(TableCell)({
  fontSize: '1.3rem'
});

interface TemplateValuesTableRowProps {
  readonly currentPointIndex: number | null;
  readonly pointIndex: number;
  readonly listPoint: TransformedFieldConfig;
  readonly onMouseExit: () => void;
  readonly onMouseEnter: (pointIndex: number) => void;
  readonly onDelete: (pointIndex: number) => void;
  readonly onRegexChange: (pointIndex: number, reg: string) => void;
  readonly onMatchChange: (pointIndex: number, val: string) => void;
  readonly cropActive: boolean;
  readonly onCrop: (pointIndex: number) => void;
}

const isRegexValid = (s: string): boolean => {
  try {
    // eslint-disable-next-line no-new
    new RegExp(s);
    return true;
  } catch {
    return false;
  }
};

const simplifyRegex = (s: string): string =>
  cleanRegExp(s).replaceAll(/\((.)\)/g, '$1');

interface RegexCheckResult {
  valid: boolean;
  messages: string[];
}

const StyledRegexInput = withStyles({
  root: {
    '& input,label': {
      fontFamily: 'monospace !important'
    }
  }
})(TextField);

const checkRegex = (s: string) => {
  if (!isRegexValid(s)) {
    return {
      valid: false,
      messages: ['Regex is invalid.']
    };
  }

  const cleanedRegExp = simplifyRegex(s);
  const hasCleanVersion = cleanedRegExp !== s;

  const out: RegexCheckResult = {
    valid: true,
    messages: []
  };

  if (hasCleanVersion) {
    out.messages.push(`Can be simplified to ${cleanedRegExp}`);
  }

  if (s.includes('  ')) {
    out.messages.push('Double whitespaces are hazardous, prefer using \\s+');
  }

  return out;
};

const TemplateValuesTableRow: FC<TemplateValuesTableRowProps> = ({
  currentPointIndex,
  pointIndex,
  listPoint,
  onMouseExit,
  onMouseEnter,
  onDelete,
  onRegexChange,
  onMatchChange,
  cropActive,
  onCrop
}) => {
  const handleMouseEnter = useCallback(() => {
    onMouseEnter(pointIndex);
  }, [onMouseEnter, pointIndex]);

  const handleRegexChanged = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onRegexChange(pointIndex, event.currentTarget.value);
    },
    [onRegexChange, pointIndex]
  );

  const handleDelete = useCallback(() => {
    onDelete(pointIndex);
  }, [pointIndex, onDelete]);

  const handleChangeMatch = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onMatchChange(pointIndex, event.currentTarget.value);
    },
    [onMatchChange, pointIndex]
  );

  const handleCrop = useCallback(() => {
    onCrop(pointIndex);
  }, [pointIndex, onCrop]);

  const checkResult = checkRegex(listPoint.regex ?? '');

  return (
    <TableRow
      className={`template-values-cell ${
        currentPointIndex === pointIndex ? 'highlight' : null
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={onMouseExit}
    >
      <LargeTableCell style={{ fontSize: '1.5rem' }}>
        {listPoint.word}
      </LargeTableCell>
      <LargeTableCell>
        <StyledRegexInput
          className="g-full-width u-margin-bottom-small"
          error={!checkResult.valid}
          helperText={
            !!checkResult.messages.length && (
              <div
                style={{
                  color: checkResult.valid ? 'darkorange' : 'red',
                  fontSize: '10px'
                }}
              >
                {checkResult.messages.map((s) => (
                  <div key={s}>- {s}</div>
                ))}
              </div>
            )
          }
          inputProps={{
            style: {
              fontFamily: 'monospace'
            }
          }}
          label="Regex"
          onChange={handleRegexChanged}
          value={listPoint.regex ?? ''}
        />
      </LargeTableCell>
      <LargeTableCell>
        <TextField
          className="g-full-width u-margin-bottom-small"
          label="Match"
          onChange={handleChangeMatch}
          value={listPoint.match ?? ''}
        />
      </LargeTableCell>
      <LargeTableCell>
        <IconButton
          className="fa fa-trash"
          onClick={handleDelete}
          title="Delete"
        />{' '}
        <IconButton
          className={`fa fa-crop-alt ${cropActive ? 'on' : ''}`}
          onClick={handleCrop}
          title="Select area"
        />
      </LargeTableCell>
    </TableRow>
  );
};

export const TemplateValuesForm: React.FC<Props> = ({
  onAddNewWord,
  dropdownValues,
  editWord,
  handleAdditionalValueChange,
  listPoints,
  setDrawRectangleMode,
  setEditWord,
  setListPoints,
  toggleValues,
  drawRectangleMode,
  newWord,
  overwriteRectangle,
  photoDimensions,
  photoUrl,
  setNewWord,
  layout
}) => {
  const [currentPointIndex, setCurrentPointIndex] = useState<number | null>(
    null
  );

  const [currentRowIndex, setCurrentRowIndex] = useState<number | undefined>();

  const handleListPointRegexChange = useCallback(
    (index: number, val: string) => {
      setListPoints((old) => {
        const newPoints = [...old];
        newPoints[index]!.regex = val;
        return newPoints;
      });
    },
    []
  );

  const handleListPointMatchChange = (index: number, val: string) => {
    const newPoints = [...listPoints];
    newPoints[index]!.match = val;

    setListPoints(newPoints);
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValueChange = {
      [event.target.name]: event.target.checked
    };

    handleAdditionalValueChange(newValueChange);
  };

  const handleInputChange = (
    event: Parameters<NonNullable<SelectInputProps['onChange']>>[0]
  ) => {
    if (!event.target.name) {
      return;
    }

    const { value, name } = event.target;

    if (name === 'presetExpirationDay' || name === 'presetExpirationMonth') {
      console.log(name);
      handleAdditionalValueChange({
        presetExpiration: {
          ...(name === 'presetExpirationDay' &&
            (value === '' ? { day: null } : { day: value as string })),
          ...(name === 'presetExpirationMonth' &&
            (value === '' ? { month: null } : { month: value as string }))
        }
      });
    } else {
      const newValueChange = {
        [name]: value === '' ? null : value
      };

      handleAdditionalValueChange(newValueChange);
    }
  };

  const toggleForm = Object.keys(toggleValues).map((k) => {
    return (
      <FormControlLabel
        color="primary"
        control={
          <StyledSwitch
            checked={toggleValues[k as keyof AdditionalToggleValues]}
            name={k}
            onChange={handleToggleChange}
          />
        }
        key={k}
        label={k}
      />
    );
  });

  const selectForm = (
    Object.entries(dropdownValues.values) as [string, 'back' | null][]
  ).map(([k, val]) => {
    return (
      <FormControl key={k}>
        <InputLabel>{k}</InputLabel>
        <Select name={k} onChange={handleInputChange} value={val ?? ''}>
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {dropdownValues.options[k]!.map((option, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem key={idx} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  });

  const tableBodyRef = useTableScroll(currentPointIndex, layout === 'row');

  const handleRowMouseExit = useCallback(() => {
    setCurrentRowIndex(undefined);
  }, []);

  const handleDelete = useCallback((pointIndex: number) => {
    setListPoints((p) => p.filter((_val, idx) => idx !== pointIndex));
  }, []);

  const handleCrop = useCallback(
    (pointIndex: number) => {
      if (pointIndex === editWord) {
        setEditWord(null);
        setDrawRectangleMode(false);
      } else {
        setEditWord(pointIndex);

        setListPoints((old) => {
          const newPoints = [...old];
          newPoints[pointIndex]!.points = [];
          return newPoints;
        });

        setDrawRectangleMode(true);
      }
    },
    [editWord]
  );

  return (
    <Grid container direction={layout} spacing={2} wrap="nowrap">
      <Grid item>
        <SmartImage
          className={layout === 'row' ? 'sticky-image' : ''}
          editIndex={editWord}
          highlightIndex={currentRowIndex}
          image={{
            dimensions: photoDimensions!,
            url: photoUrl!
          }}
          listPoints={listPoints.map((point, idx) =>
            point.points.map((pointPoints) => ({
              ...pointPoints,
              widx: idx
            }))
          )}
          maxHeightPerc={0.7}
          noPoints={false}
          onMouseOutPoint={(pts) => {
            if (pts.length) {
              setCurrentPointIndex(null);
            }
          }}
          onMouseOverPoint={(pts) => {
            if (pts.length) {
              setCurrentPointIndex(
                (pts[0]! as unknown as { widx: number }).widx
              );
            }
          }}
          readonly={!drawRectangleMode}
          twoPointClick
          updatePoints={overwriteRectangle}
        />
      </Grid>
      <Grid item>
        <Box marginBottom={6}>
          <FormGroup style={{ marginBottom: '24px' }}>
            <TextField
              InputProps={{
                endAdornment: (
                  <IconButton
                    className="fa fa-plus fa-solid"
                    onClick={onAddNewWord}
                    title="Add new word"
                  />
                )
              }}
              className="g-full-width u-margin-bottom-small"
              label="Field name"
              onChange={(e) => {
                setNewWord(e.target.value);
              }}
              value={newWord}
            />
          </FormGroup>
        </Box>
        <Table
          padding="none"
          size="medium"
          stickyHeader
          style={{
            fontSize: '2rem'
          }}
        >
          <TableHead>
            <TableRow>
              <LargeTableCell>Name</LargeTableCell>
              <LargeTableCell>Regex</LargeTableCell>
              <LargeTableCell>Match</LargeTableCell>
              <LargeTableCell>Actions</LargeTableCell>
            </TableRow>
          </TableHead>
          <TableBody ref={tableBodyRef}>
            {listPoints.map((listPoint, pointIndex) => (
              <TemplateValuesTableRow
                cropActive={editWord === pointIndex}
                currentPointIndex={currentPointIndex}
                // eslint-disable-next-line react/no-array-index-key
                key={pointIndex}
                listPoint={listPoint}
                onCrop={handleCrop}
                onDelete={handleDelete}
                onMatchChange={handleListPointMatchChange}
                onMouseEnter={setCurrentRowIndex}
                onMouseExit={handleRowMouseExit}
                onRegexChange={handleListPointRegexChange}
                pointIndex={pointIndex}
              />
            ))}
          </TableBody>
        </Table>
        <FormGroup row={false}>{selectForm}</FormGroup>
        <FormGroup row>{toggleForm}</FormGroup>
        <FormGroup row>
          <a
            href="https://docs.google.com/spreadsheets/d/1h3VepHOIWH1j0fN_qJlZ2Bb64BfZLG67P9lC216Jxzo/edit#gid=0"
            rel="noreferrer"
            target="_blank"
          >
            Cheat sheet
          </a>
        </FormGroup>
      </Grid>
    </Grid>
  );
};
